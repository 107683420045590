import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Bildungsgutschein from "../components/Bildungsgutschein";

const BildungsgutscheinPageTemplate = ({ title, blurbs }) => {
  return <Bildungsgutschein title={title} blurbs={blurbs} />;
};

const BildungsgutscheinPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout location={location}>
      <SEO
        title={frontmatter.title}
        location={location}
        description={"Study for free the bildungsgutschein."}
      />
      <BildungsgutscheinPageTemplate
        title={frontmatter.title}
        blurbs={frontmatter.blurbs}
      />
    </Layout>
  );
};

BildungsgutscheinPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default BildungsgutscheinPage;

export const bildungsgutscheinPageQuery = graphql`
  query BildungsgutscheinPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        blurbs {
          text
          title
        }
      }
    }
  }
`;
